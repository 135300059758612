import React, { useState } from "react"
import { Link } from "gatsby"
import { displayItem, Col, Modal, Row, notification, Icon } from "antd"
import "antd/lib/badge/style/index.css"
import { globalHistory } from "@reach/router"
import CardDemo from "./b2c/CardForm"
import Layout from "./Layout"
import Carousel from "./Carousel"

const ProductdisplayItems = ({
  displayItems,
  large = false,
  cartItems,
  addItemToCart,
  isInCart,
}) => {
  const [modalProduct, setModalProduct] = useState(null)
  const [modalLearn, setModalLearn] = useState(null)

  console.log("displayItems", displayItems)
  const learnModal = (
    <Modal
      title={<h3>{modalLearn ? modalLearn.title : null}</h3>}
      visible={!!modalLearn}
      footer={
        <button
          onClick={() => setModalLearn(null)}
          style={{ width: "100%" }}
          className="button button-primary button-block"
        >
          Return to Shopping Cart
        </button>
      }
      onOk={() => setModalLearn(null)}
      onCancel={() => setModalLearn(null)}
    >
      {modalLearn ? (
        <p>
          <div dangerouslySetInnerHTML={{ __html: modalLearn.content }} />
        </p>
      ) : null}
    </Modal>
  )

  const addonModal = (
    <Modal
      width={800}
      title={
        <div>
          <h3 style={{ fontSize: "24px" }}>
            <Icon
              type="check-circle"
              style={{
                fontSize: "2em",
                color: "green",
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: 15,
              }}
            />
            Your portal subscription was added to your cart.
          </h3>
          <h3 style={{ fontSize: "24px" }}>
            Would you like to purchase additional resources?
          </h3>
        </div>
      }
      visible={!!modalProduct}
      footer={
        <button
          onClick={() => setModalProduct(null)}
          style={{ width: "100%" }}
          className="button button-primary button-block"
        >
          Return to Shopping Cart
        </button>
      }
      onOk={() => setModalProduct(null)}
      onCancel={() => setModalProduct(null)}
    >
      {modalProduct ? (
        <p>
          <div style={{ marginTop: 20 }}>
            {modalProduct.addon.map(addon => {
              return (
                addon.title && (
                  <Row
                    gutter={32}
                    key={addon.title}
                    style={{ marginBottom: 50 }}
                    type={"flex"}
                    className=""
                  >
                    <Col span={8}>
                      <Carousel noAutoplay={true} images={addon.images} />
                      <button
                        disabled={isInCart(addon)}
                        onClick={() => addItemToCart(addon)}
                        className="button button-primary button-block"
                        style={{ width: "100%", marginTop: 20 }}
                      >
                        {isInCart(addon) ? (
                          <span>Added!</span>
                        ) : (
                          <span>Add to Cart</span>
                        )}
                      </button>
                    </Col>
                    <Col span={16}>
                      <span>
                        <b>{addon.title}</b> - {addon.price}
                      </span>
                      <i
                        className="text-sm no-bullet-space"
                        dangerouslySetInnerHTML={{ __html: addon.description }}
                      />
                    </Col>
                  </Row>
                )
              )
            })}
          </div>
        </p>
      ) : null}
    </Modal>
  )

  return (
    <section className={`${large && "large"} features section text-center`}>
      {addonModal}
      {learnModal}
      <div className="features-wrap">
        {displayItems.map(displayItem => {
          return (
            <div className="feature ">
              <div className="feature-inner">
                <div className="feature-icon">
                  {isInCart(displayItem) && (
                    <div
                      className="button button-sm button-block button-primary"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      Added to Cart!
                    </div>
                  )}
                  {displayItem.carousel && displayItem.carousel.length ? (
                    <Carousel images={displayItem.carousel} />
                  ) : (
                    <div
                      style={{
                        backgroundImage: `url(${encodeURI(displayItem.image)})`,
                      }}
                    />
                  )}
                </div>
                <div className="feature-content">
                  <h4 className="feature-title">{displayItem.title}</h4>
                  <p className="text-sm">{displayItem.description}</p>
                  <p className="text-sm">{displayItem.price}</p>
                </div>
                <div className="cta">
                  <Row>
                    <Col span={12}>
                      <button
                        disabled={
                          isInCart(displayItem) || displayItem.coming_soon
                        }
                        onClick={() => {
                          addItemToCart(displayItem)
                          setModalProduct(displayItem)
                        }}
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        className="button button-primary button-block button-full"
                      >
                        Subscribe
                      </button>
                    </Col>
                    <Col span={12}>
                      <a
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        className="button button-primary button-block button-full"
                        onClick={() =>
                          setModalLearn({
                            title: displayItem.title,
                            content: displayItem.content,
                          })
                        }
                      >
                        Learn More
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
export default ProductdisplayItems
