import { Avatar, Card, List } from "antd"
import "antd/lib/modal/style/index.css"
import "antd/lib/steps/style/index.css"
import "antd/lib/card/style/index.css"
import "antd/lib/alert/style/index.css"
import "antd/lib/list/style/index.css"
import "antd/lib/avatar/style/index.css"
import React from "react"

const   getImageFromItem = (item) => item && (item.image || item.images && item.images[0])

export default ({ cartItems = [], removeItemFromCart, showCardFormModal }) => {
  const cardStyle = removeItemFromCart ? {borderLeft: 1} : {}
  return <div>
    <Card style={{  borderRadius: 0, ...cardStyle }}>
      <List
        itemLayout="horizontal"
        dataSource={cartItems}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              removeItemFromCart && <a key="remove" onClick={() => removeItemFromCart(item)}>remove</a>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar size={64} src={getImageFromItem(item)} shape="square"/>}
              title={<div style={{ marginTop: 0 }}>
                {item.title}
              </div>}
              description={item.price}
            />
          </List.Item>
        )}
      />
      <br/>
      {showCardFormModal &&
      <a className="button button-primary button-block"
         onClick={showCardFormModal}>Proceed to Checkout
      </a>
      }
    </Card>
  </div>
}
