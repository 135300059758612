import React, { Component } from "react"
import { notification, Spin } from "antd"
import "antd/lib/spin/style/index.css"
import "antd/lib/notification/style/index.css"
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider,
} from "react-stripe-elements"
import { navigate } from "../../../.cache/gatsby-browser-entry"
import { withFirebase } from "./../Firebase"
// You can customize your Elements
//
// to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontFamily: "Helvetica Neue",
        fontSize: "15px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  }
}

class _CardForm extends Component {
  state = {
    errorMessage: "",
    submitting: false,
    showShipAddr: false,
  }
  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message })
    }
  }
  handleShipAddrCheckbox = evt => {
    this.setState(prevState => {
      return { showShipAddr: !prevState.showShipAddr }
    })
  }
  handleSubmit = async evt => {
    evt.preventDefault()
    try {
      let ifrm = document.querySelector(".iframehack");
      let win;
      try {
        win = ifrm.contentWindow;
      } catch(e) {
        win = ifrm.contentWindow;
      }
      let userEmail = document.querySelector(".useremail").value
      userEmail = userEmail.toLowerCase();
      const userName = document.querySelector(".username").value
      var obj = {
        emailForSignIn: userEmail,
        usernameForSignIn: userName,
      }
      const ret = win.postMessage(
        JSON.stringify({ key: "storage", data: obj }),
        "*"
      )
      this.setState({ submitting: true })
      this.props.firebase
        .doSignInWithLink(userEmail, "individuals")
        .then(authUser => {
          console.log(authUser)
        })
      const source = await this.props.stripe.createSource({ type: "card" })
      await this.props.handleResult(source.source.id)
    } catch (e) {
      console.log(e)
      notification.error({
        message: "Payment Error",
        description:
          "We failed to process your payment. Please contact tracie@livingwisely.org for assistance.",
      })
    } finally {
      this.setState({ submitting: false })
    }
  }

  render() {
    const { submitting } = this.state
    return (
      <Spin
        size={"large"}
        spinning={submitting}
        tip="Payment processing - Please do not reload"
      >
        <div className="fancy-form">
          <form onSubmit={this.handleSubmit.bind(this)} id="stripe-form">
            <div className="group">
              <label>
                <span>Card Details</span>
                <CardElement
                  className="field"
                  onChange={this.handleChange}
                  {...createOptions()}
                />
              </label>
            </div>
            <div className="group">
              <label>
                <span>Name</span>
                <input
                  id="name"
                  name="name"
                  className="field username"
                  placeholder="Jane Doe"
                  required
                />
              </label>
              <label>
                <span>Email</span>
                <input
                  id="email"
                  name="email"
                  className="field useremail"
                  type="email"
                  placeholder="you@email.com"
                  required
                />
              </label>
            </div>
            <div className="group">
              <label>
                <span>Address</span>
                <input
                  id="address-line1"
                  name="address_line1"
                  className="field"
                  placeholder="77 Winchester Lane"
                  required
                />
              </label>
              <label>
                <span>Address (cont.)</span>
                <input
                  id="address-line2"
                  name="address_line2"
                  className="field"
                  placeholder=""
                />
              </label>
              <label>
                <span>City</span>
                <input
                  id="address-city"
                  name="address_city"
                  className="field"
                  placeholder="Coachella"
                  required
                />
              </label>
              <label>
                <span>State</span>
                <input
                  id="address-state"
                  name="address_state"
                  className="field"
                  placeholder="CA"
                  required
                />
              </label>
              <label>
                <span>ZIP</span>
                <input
                  id="address-zip"
                  name="address_zip"
                  className="field"
                  placeholder="92236"
                  required
                />
              </label>
              <label>
                <span>Country</span>
                <input
                  id="address-country"
                  name="address_country"
                  className="field"
                  placeholder="United States"
                  required
                />
              </label>
            </div>

            <div className="group">
              <label>
                <span>Use different shipping addess</span>
                <input
                  id="use-diff-shipping-address"
                  name="use_diff_shipping_address"
                  type="checkbox"
                  className="chkbox"
                  onClick={this.handleShipAddrCheckbox}
                  checked={this.state.showShipAddr}
                />
              </label>
              {this.state.showShipAddr && (
                <>
                  <label>
                    <span>Address</span>
                    <input
                      id="ship-address-line1"
                      name="ship_address_line1"
                      className="field"
                      placeholder="77 Winchester Lane"
                    />
                  </label>
                  <label>
                    <span>Address (cont.)</span>
                    <input
                      id="ship-address-line2"
                      name="ship_address_line2"
                      className="field"
                      placeholder=""
                    />
                  </label>
                  <label>
                    <span>City</span>
                    <input
                      id="ship-address-city"
                      name="ship_address_city"
                      className="field"
                      placeholder="Coachella"
                      required
                    />
                  </label>
                  <label>
                    <span>State</span>
                    <input
                      id="ship-address-state"
                      name="ship_address_state"
                      className="field"
                      placeholder="CA"
                      required
                    />
                  </label>
                  <label>
                    <span>ZIP</span>
                    <input
                      id="ship-address-zip"
                      name="ship_address_zip"
                      className="field"
                      placeholder="92236"
                      required
                    />
                  </label>
                  <label>
                    <span>Country</span>
                    <input
                      id="ship-address-country"
                      name="ship_address_country"
                      className="field"
                      placeholder="United States"
                      required
                    />
                  </label>
                </>
              )}
            </div>

            <div className="error" role="alert">
              {this.state.errorMessage}
            </div>
            <button className="button-block button-primary button button-full">
              Pay
            </button>
          </form>
          <iframe
            src="https://individuals.livingwisely.org/"
            class="iframehack"
            width="1"
            height="1"
            style={{ visibility: "hidden" }}
          ></iframe>
        </div>
      </Spin>
    )
  }
}
const CardForm = injectStripe(withFirebase(_CardForm))
export default class CardDemo extends Component {
  render() {
    return (
      <div>
        {this.props.children}

        <StripeProvider apiKey={`${process.env.STRIPE_PUBLIC_KEY}`}>
          <Elements>
            <CardForm handleResult={this.props.onSubmit} />
          </Elements>
        </StripeProvider>
      </div>
    )
  }
}
