import React from "react"
import Layout from "../../components/Layout"
import { graphql, navigate } from "gatsby"
import { Avatar, Card, Col, Icon, List, Modal, Row, Steps } from "antd"
import "antd/lib/modal/style/index.css"
import ProductCards from "../../components/ProductCards"
import "antd/lib/steps/style/index.css"
import "antd/lib/card/style/index.css"
import "antd/lib/alert/style/index.css"
import "antd/lib/list/style/index.css"
import "antd/lib/avatar/style/index.css"
import CardDemo from "../../components/b2c/CardForm"
import CartItems from "../../components/b2c/CartItems"
import withLocation from "../../components/withLocation"

export const query = graphql`
  query getShoppingCartData {
    markdownRemark(frontmatter: { url: { eq: "cart" } }) {
      id
      frontmatter {
        title
        subtitle
        product {
          title
          content
          image
          plan_id
          price
          description
          coming_soon
          hidden
          addon {
            title
            sku
            sku_mn
            price
            images
            description
          }
        }
      }
    }
  }
`
class Cart extends React.Component {
  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  state = {
    cartItems: [],
    cardFormVisible: false,
  }
  addItemToCart = newItem => {
    if (this.isInCart(newItem)) return
    this.setState({
      cartItems: [...this.state.cartItems, newItem],
    })
  }
  removeItemFromCart = item => {
    if (!this.isInCart(item)) return
    this.setState({
      cartItems: this.state.cartItems.filter(
        cartItem => cartItem.title !== item.title
      ),
    })
  }
  isInCart = item => {
    return this.state.cartItems.some(cartItem => cartItem.title === item.title)
  }
  showCardFormModal = () => {
    this.setState({
      cardFormVisible: true,
    })
  }
  hideCardFormModal = () => {
    this.setState({
      cardFormVisible: false,
    })
  }
  addedSkus = () => {
    const skus = Object.keys(this.state.skus)
    return skus.filter(id => {
      return this.state.skus[id]
    })
  }
  addedPlans = () => {
    const plans = Object.keys(this.state.plans)
    return plans.filter(id => {
      return this.state.plans[id]
    })
  }
  getSubscriptions = () => {
    return this.state.cartItems.filter(cartItem => !!cartItem.plan_id)
  }
  getItems = () => {
    return this.state.cartItems.filter(cartItem => !!cartItem.sku)
  }
  getImageFromItem = item =>
    item && (item.image || (item.images && item.images[0]))

  componentDidMount() {
    this.stripe = window.Stripe(`${process.env.STRIPE_PUBLIC_KEY}`)
  }

  purchaseSubscriptions = async stripeToken => {
    try {
      console.log(stripeToken)
      const fD = new FormData(document.getElementById("stripe-form"))
      const diffShippingAddressPrefix =
        fD.get("use_diff_shipping_address") === "on" ? "ship_" : ""
      const customerData = {
        email: fD.get("email"),
        address: {
          line1: fD.get("address_line1"),
          line2: fD.get("address_line2"),
          city: fD.get("address_city"),
          state: fD.get("address_state"),
          postal_code: fD.get("address_zip"),
          country: fD.get("address_country"),
        },
        shipping: {
          address: {
            line1: fD.get(diffShippingAddressPrefix + "address_line1"),
            line2: fD.get(diffShippingAddressPrefix + "address_line2"),
            city: fD.get(diffShippingAddressPrefix + "address_city"),
            state: fD.get(diffShippingAddressPrefix + "address_state"),
            postal_code: fD.get(diffShippingAddressPrefix + "address_zip"),
            country: fD.get(diffShippingAddressPrefix + "address_country"),
          },
          name: fD.get("name"),
        },
        name: fD.get("name"),
      }
      const purchase = await fetch(
        "/.netlify/functions/purchase-subscriptions",
        {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify({
            stripeToken,
            customerData,
            items: this.getItems(),
            subscriptions: this.getSubscriptions(),
          }),
        }
      )

      const text = await purchase.text()

      if (purchase.status === 200) {
        navigate("/individuals/successful-purchase")
      } else {
        throw Error(text)
      }
    } catch (e) {
      throw Error(e)
    }
  }

  render() {
    const { data } = this.props
    const { plans } = this.props.search // comma separated list of product names
    const { cartItems, cardFormVisible } = this.state
    const { frontmatter } = data.markdownRemark

    // Hide products with a hidden attribute, require query string 'plans' with comma separated list of product names to show
    const displayItems = (frontmatter.product || []).filter(product =>
      plans && plans.length ? plans.includes(product.title) : !product.hidden
    )

    return (
      <Layout
        noContainer={true}
        showCta={false}
        b2c={true}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
      >
        {cartItems.length > 0 && (
          <div>
            <Row type={"flex"} className="checkout-row container">
              <Card
                style={{
                  backgroundColor: "#fafafa",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <Row gutter={16} type={"flex"} align={"middle"}>
                  <h3>Your Shopping Cart</h3>
                </Row>
              </Card>
              <CartItems
                showCardFormModal={this.showCardFormModal}
                cartItems={cartItems}
                removeItemFromCart={this.removeItemFromCart}
              />
            </Row>
          </div>
        )}

        <ProductCards
          displayItems={displayItems}
          cart={true}
          isInCart={this.isInCart}
          cartItems={cartItems}
          addItemToCart={this.addItemToCart}
        />

        <Modal
          title={<h3>Your Payment Information</h3>}
          visible={cardFormVisible}
          footer={null}
          onCancel={this.hideCardFormModal}
          width={650}
        >
          <CardDemo onSubmit={this.purchaseSubscriptions}>
            <CartItems cartItems={cartItems} />
          </CardDemo>
        </Modal>
      </Layout>
    )
  }
}

export default withLocation(Cart)
